<template>
  <div>
    <a-drawer
      title="New Message"
      :visible="showNewMessageDrawer"
      :width="width > '640' ? 375 : 320"
      @close="onCloseNewMessageDrawer"
    >
      <a-form-model
        ref="messageForm"
        :rules="rules"
        layout="vertical"
        :model="newMessageForm"
      >
        <a-form-model-item label="Title" prop="title">
          <a-input
            v-model="newMessageForm.title"
            type="title"
            placeholder="Message Title"
          />
        </a-form-model-item>
        <a-form-model-item label="Message" prop="message">
          <a-textarea
            v-model="newMessageForm.message"
            placeholder="Enter your message here"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="flex mt-5 items-center justify-end">
        <a-button
          type="primary"
          :loading="sendingNewMessage"
          @click="onSendMessage"
          >Send Message</a-button
        >
      </div>
    </a-drawer>
    <a-drawer
      title="View Message"
      :width="width > '640' ? 375 : 320"
      :visible="showViewMessageDrawer"
      @close="onCloseViewMessageDrawer"
    >
      <h4>{{ selectedMessage.title }}</h4>
      <p>
        {{ selectedMessage.message }}
      </p>
    </a-drawer>
    <tab-headers>
      Support
      <template #text> Send message to support here </template>
    </tab-headers>
    <div class="flex items-center justify-end mb-5">
      <a-button
        type="primary"
        icon="message"
        @click="showNewMessageDrawer = true"
      >
        New Message
      </a-button>
    </div>
    <div class="bg-white rounded shadow">
      <a-tabs
        v-model="activeKey"
        :tab-position="width > 640 ? 'top' : 'left'"
        default-active-key="2"
      >
        <a-tab-pane key="1" tab="Received Messages"> </a-tab-pane>
        <a-tab-pane key="2" tab="Sent Messages"> </a-tab-pane>
      </a-tabs>
      <div class="mt-3 bg-white shadow-md rounded-md">
        <div v-if="activeKey == '1'">
          <a-table
            :data-source="receivedMessages"
            :columns="headersForSentMessage"
            :loading="gettingReceivedMessage"
          >
            <span slot="message" slot-scope="record">
              {{ record.message.slice(0, 150) + ' ...' }}
            </span>
            <span slot="action" slot-scope="record">
              <a-button @click="showMessageDetails(record, 'view')"
                >View
              </a-button>
            </span>
          </a-table>
        </div>
        <div v-if="activeKey == '2'">
          <a-table
            :data-source="sentMessages"
            :columns="headersForSentMessage"
            :loading="gettingSentMessage"
          >
            <span slot="message" slot-scope="record">
              {{ record.message.slice(0, 150) + ' ...' }}
            </span>
            <span slot="action" slot-scope="record">
              <a-button @click="showMessageDetails(record, 'view')"
                >View
              </a-button>
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import messageApi from '../../api/message'

const headersForSentMessage = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Message',
    key: 'message',
    scopedSlots: { customRender: 'message' },
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'LawyerSupport',
  components: { TabHeaders },
  layout: 'lawyerDashboard',
  data() {
    return {
      activeKey: '1',
      headersForSentMessage,
      showNewMessageDrawer: false,
      showViewMessageDrawer: false,
      selectedMessage: {},
      sendingNewMessage: false,
      gettingSentMessage: false,
      gettingReceivedMessage: false,
      receivedMessages: [],
      sentMessages: [],
      newMessageForm: {
        message: '',
        title: '',
      },
      rules: {
        title: { required: true, message: 'Please fill this field' },
        message: { required: true, message: 'Please fill this field' },
      },
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
  },
  created() {
    this.getMessages()
    this.getReceivedMessages()
  },
  methods: {
    async showMessageDetails(record, which) {
      if (which !== 'view') {
        await messageApi(this.axios).markRead({ messageId: record._id })
      }
      this.selectedMessage = record
      this.showViewMessageDrawer = true
    },
    onCloseNewMessageDrawer() {
      this.showNewMessageDrawer = false
    },
    onCloseViewMessageDrawer() {
      this.showViewMessageDrawer = false
    },
    onSendMessage() {
      this.$refs.messageForm.validate((valid) => {
        if (valid) this.sendMessage()
        else return false
      })
    },
    async getReceivedMessages() {
      this.gettingSentMessage = true
      const req = await messageApi(this.axios).get(
        {
          type: 'all',
        },
        {
          recipient: this.user._id,
        }
      )
      this.receivedMessages = req.data.data
      this.gettingReceivedMessage = false
    },
    async getMessages() {
      this.gettingSentMessage = true
      const req = await messageApi(this.axios).get(
        {
          type: 'all',
        },
        {
          sender: this.user._id,
        }
      )
      this.sentMessages = req.data.data
      this.gettingSentMessage = false
    },
    async sendMessage() {
      this.sendingNewMessage = true
      const req = await messageApi(this.axios).send({
        message: this.newMessageForm.message,
        title: this.newMessageForm.title,
        sendTo: 'admin',
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to send message',
          description: req.message,
        })
        this.sendingNewMessage = false
        return
      }
      this.$notification.success({
        message: 'Message sent successfully',
        description: req.message,
      })
      this.getMessages()
      this.showNewMessageDrawer = false
      this.newMessageForm.title = ''
      this.newMessageForm.message = ''
      this.sendingNewMessage = false
    },
  },
}
</script>
